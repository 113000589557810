<template>
  <div class="container" ref="wrapper">
    <van-nav-bar
      title="我的定制"
      left-arrow
      @click-left="$router.replace({ path: '/' })"
    >
      <template #right>
        <div>
          <van-icon
            name="chart-trending-o"
            @click="tochart"
            color="#1989fa"
            size="12"
            v-if="hasDepartmentDashboardPermission || hasDashboardPermission"
            ><span style="position: relative; top: -2px">统计</span></van-icon
          >
          <van-icon
            name="question"
            @click="tohelp()"
            color="#1989fa"
            size="10"
            style="margin-left: 5px"
            ><span style="position: relative; top: -2px">文档</span></van-icon
          >
          <!-- <van-icon
            name="plus"
            size="10"
            style="margin-left: 10px"
            @click="toadd()"
            ><span style="position: relative; top: -2px"
              >测试单</span
            ></van-icon
          > -->
        </div>
      </template>
    </van-nav-bar>
    <van-sticky>
      <van-dropdown-menu>
        <van-dropdown-item
          v-model="page.sorter"
          :options="sorterOptions"
          @change="onSearch"
        />
        <van-dropdown-item
          v-model="query.flowStatus"
          :options="flowStatusOptions"
          @change="onSearch"
        />
        <van-dropdown-item title="更多筛选" ref="filter">
          <van-cell center>
            <template #right-icon>
              <van-field
                readonly
                clickable
                name="calendar"
                :value="query.timeRange"
                label="选择日期范围"
                placeholder="点击选择"
                @click="showCalendar = true"
              />
              <van-calendar
                v-model="showCalendar"
                type="range"
                @confirm="onSelectedTimeRange"
                :min-date="minDate"
              />
            </template>
          </van-cell>
          <van-cell center title="订单id">
            <template #right-icon>
              <van-search v-model="query.orderId" placeholder="请输入订单id" />
            </template>
          </van-cell>
          <van-cell center title="A3客户id">
            <template #right-icon>
              <van-search
                v-model="query.a3CustomerId"
                placeholder="请输入A3客户id"
              />
            </template>
          </van-cell>
          <van-cell center title="定制信息">
            <template #right-icon>
              <van-search v-model="query.keyword" placeholder="请输入关键词" />
            </template>
          </van-cell>
          <van-cell center title="部门" v-if="isDepartmentManager">
            <template #right-icon>
              <van-search
                v-model="query.departmentName"
                placeholder="请输入部门名称"
              />
            </template>
          </van-cell>
          <van-cell center title="姓名" v-if="isDepartmentManager">
            <template #right-icon>
              <van-search v-model="query.userName" placeholder="请输入姓名" />
            </template>
          </van-cell>
          <div style="padding: 5px 16px">
            <van-button round style="width: 45%" @click="onReset">
              重置
            </van-button>
            <van-button
              type="danger"
              round
              :loading="loading"
              style="width: 45%; margin-left: 9%"
              @click="
                () => {
                  this.$refs.filter.toggle(false);
                  onSearch();
                }
              "
            >
              确定
            </van-button>
          </div>
        </van-dropdown-item>
      </van-dropdown-menu>
    </van-sticky>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-cell-group v-for="item in list" :key="item.id">
          <van-cell title-class="van-cell-title4" center>
            <template #title>
              {{
                item.itemType == 0
                  ? "个人定制"
                  : item.itemType == 1
                  ? "企业定制"
                  : item.itemType == 2
                  ? "职称定制"
                  : ""
              }}
              <div
                style="float: right; margin-left: 5px; cursor: pointer"
                @click.stop="toShowRecords(item)"
              >
                <van-tag type="success" round plain v-if="item.flowStatus == 0"
                  >等待完善</van-tag
                >
                <van-tag
                  type="success"
                  round
                  plain
                  v-else-if="item.flowStatus == 10"
                  >等待审核</van-tag
                >
                <van-tag
                  type="danger"
                  round
                  plain
                  v-else-if="item.flowStatus == 14"
                  >审核不通过</van-tag
                >
                <van-tag
                  type="success"
                  round
                  plain
                  v-else-if="item.flowStatus == 29"
                  >等待修改设计图</van-tag
                >
                <van-tag
                  type="success"
                  round
                  plain
                  v-else-if="item.flowStatus == 15"
                  >等待设计图</van-tag
                >
                <van-tag
                  type="success"
                  round
                  plain
                  v-else-if="item.flowStatus == 20"
                  >请确认设计图</van-tag
                >
                <van-tag
                  type="success"
                  round
                  plain
                  v-else-if="item.flowStatus == 30"
                  >等待设计上传设计文件</van-tag
                >
                <van-tag
                  type="success"
                  round
                  plain
                  v-else-if="item.flowStatus == 35"
                  >工厂制作中</van-tag
                >
                <van-tag
                  type="success"
                  round
                  plain
                  v-else-if="item.flowStatus == 40"
                  >等待仓库发货</van-tag
                >
                <van-tag
                  type="success"
                  round
                  plain
                  v-else-if="item.flowStatus == 50"
                  >仓库已发货</van-tag
                >
              </div>
              <div style="float: right; cursor: pointer">
                <van-tag
                  type="danger"
                  v-if="
                    $store.state.user.id == item.creatorId &&
                    item.flowStatus == 0
                  "
                  @click="toedit(item)"
                  >点我完善</van-tag
                >
                <van-tag
                  type="primary"
                  v-else-if="
                    $store.state.user.id == item.creatorId &&
                    item.flowStatus == 14
                  "
                  @click="toedit(item)"
                  >点我修改</van-tag
                >
                <van-tag
                  v-if="isDepartmentManager && item.flowStatus == 10"
                  type="primary"
                  @click="toaudit(item)"
                  >点我审核</van-tag
                >
              </div>
            </template>
            <template #label>
              <van-row>
                <van-col span="7"><strong>订单编号：</strong></van-col>
                <van-col span="17">{{ item.orderId }}</van-col>
              </van-row>
              <van-row>
                <van-col span="7"><strong>A3客户：</strong></van-col>
                <van-col span="17">{{ item.a3CustomerId }}</van-col>
              </van-row>
              <van-row v-if="item.flowStatus==50">
                <van-col span="7"><strong>发货信息：</strong></van-col>
                <van-col span="17">{{ item.logisticsNumber }} {{ item.logisticsName }}</van-col>
              </van-row>
              <van-row v-if="item.flowStatus==50">
                <van-col span="7"><strong>发货时间：</strong></van-col>
                <van-col span="17">{{ item.shippingTime | dateFormat("YYYY年MM月DD日 HH时")}}</van-col>
              </van-row>
              <van-row v-if="item.schemeNo > 0">
                <van-col span="7"><strong>方案编号：</strong></van-col>
                <van-col span="17">{{ item.schemeNo }}</van-col>
              </van-row>
              <van-row v-if="item.itemType == 0 || item.itemType == 2">
                <van-col span="7"><strong>定制姓名：</strong></van-col>
                <van-col span="17">{{ item.name }}</van-col>
              </van-row>
              <van-row v-if="item.itemType == 0">
                <van-col span="7"><strong>个人座右铭：</strong></van-col>
                <van-col span="17">{{ item.motto }}</van-col>
              </van-row>
              <van-row v-if="item.itemType == 2">
                <van-col span="7"><strong>个人职称：</strong></van-col>
                <van-col span="17">{{ item.jobTitle }}</van-col>
              </van-row>
              <van-row v-if="item.itemType == 1 || item.itemType == 2">
                <van-col span="7"><strong>企业名称：</strong></van-col>
                <van-col span="17">{{ item.company }}</van-col>
              </van-row>
              <van-row v-if="item.itemType == 1 || item.itemType == 2">
                <van-col span="7"><strong>企业文化：</strong></van-col>
                <van-col span="17">{{ item.companyCulture }}</van-col>
              </van-row>
              <van-row>
                <van-col span="7"><strong>企业LOGO：</strong></van-col>
                <van-col span="17">
                  <van-image
                    v-if="item.companyLogo"
                    width="100"
                    height="100"
                    :src="item.companyLogo"
                /></van-col>
              </van-row>
              <van-row if="item.remark">
                <van-col span="7"><strong>备注：</strong></van-col>
                <van-col span="17">{{ item.remark }}</van-col>
              </van-row>
              <van-row v-if="$store.state.user.id != item.creatorId">
                <van-col span="7"><strong>提交人：</strong></van-col>
                <van-col span="17">{{ item.creatorName }} </van-col>
              </van-row>
              <van-row v-if="$store.state.user.id != item.creatorId">
                <van-col span="7"><strong>提交人部门：</strong></van-col>
                <van-col span="17">{{ item.creatorDepartment }} </van-col>
              </van-row>
              <van-row>
                <van-col span="7"><strong>创建时间：</strong></van-col>
                <van-col span="17">{{
                  item.creationTime | dateFormat("YYYY/MM/DD HH:mm")
                }}</van-col>
              </van-row>
              <van-row v-if="item.flowStatus >= 20">
                <van-col span="7"><strong>设计效果图：</strong></van-col>
                <van-col span="17">
                  <van-tag
                    style="cursor: pointer"
                    type="primary"
                    @click="showDesignImg(item)"
                    >点我查看</van-tag
                  >
                </van-col>
              </van-row>
            </template>
            <template #extra>
              <span style="cursor: pointer" @click="toedit(item)"
                ><van-icon name="arrow"
              /></span>
            </template>
          </van-cell>
        </van-cell-group>
      </van-list>
    </van-pull-refresh>
    <van-action-sheet
      v-model="showDesignImgDialog"
      title="请与客户沟通设计图后并确认"
    >
      <div class="content">
        <van-tabs>
          <van-tab
            :title="`第${item.Version}版`"
            v-for="(item, index) in currentItem.designImgs"
            :key="index"
          >
            <van-swipe style="height: 300px; padding: 5px">
              <van-swipe-item v-for="(image, index) in item.Imgs" :key="index">
                <img
                  v-lazy="image"
                  style="height: 100%"
                  @click="previewImg(item.Imgs)"
                />
              </van-swipe-item>
            </van-swipe>
            <p
              v-if="
                $store.state.user.id == currentItem.creatorId &&
                currentItem.flowStatus == 20 &&
                item.NoPassReason
              "
              style="padding: 5px; font-size: 12px"
            >
              <strong>我的留言：</strong>{{ item.NoPassReason }}
            </p>
            <p
              v-if="
                $store.state.user.id == currentItem.creatorId &&
                currentItem.flowStatus == 20 &&
                item.RefuseReason
              "
              style="padding: 5px; font-size: 12px"
            >
              <strong>设计反馈：</strong>{{ item.RefuseReason }}
            </p>
          </van-tab>
        </van-tabs>
      </div>
      <van-button
        v-if="
          $store.state.user.id == currentItem.creatorId &&
          currentItem.flowStatus == 20
        "
        type="info"
        size="mini"
        style="float: right; margin: 5px"
        @click="confirmDesign()"
        >确认设计图</van-button
      >
      <van-button
        v-if="
          $store.state.user.id == currentItem.creatorId &&
          currentItem.flowStatus == 20
        "
        type="danger"
        size="mini"
        style="float: right; margin: 5px"
        @click="reDesign()"
        >{{
          currentItem.designImgs &&
          currentItem.designImgs.length > 0 &&
          currentItem.designImgs[0].NoPassReason
            ? "重新留言"
            : "需修改设计"
        }}</van-button
      >
    </van-action-sheet>
    <van-popup
      v-model="showReDesignImgDialog"
      :style="{ width: '90%' }"
      closeable
    >
      <van-form @submit="submitReDesign()">
        <van-field
          v-model="reDesignImgReason"
          autosize
          label="给设计留言"
          type="textarea"
          maxlength="100"
          placeholder="请输入需修改设计的内容及原因"
          show-word-limit
          style="margin-top: 25px"
          :rules="[{ required: true }]"
        />
        <van-button
          v-if="
            $store.state.user.id == currentItem.creatorId &&
            currentItem.flowStatus == 20
          "
          type="info"
          size="mini"
          style="float: right; margin: 5px"
          native-type="submit"
          >确定</van-button
        >
      </van-form>
    </van-popup>
    <van-popup v-model="auditShow" closeable :style="{ width: '80%' }">
      <div style="padding: 20px; text-align: right; line-height: 30px">
        <p style="text-align: center"><strong>审核提示</strong></p>
        <p style="text-align: left">
          审核通过后流程进入设计效果图环节，是否通过？
        </p>
        <van-button type="danger" size="mini" @click="audit(false)"
          >不通过</van-button
        >
        <van-button
          type="info"
          size="mini"
          @click="audit(true)"
          style="margin-left: 10px"
          >通过</van-button
        >
      </div>
    </van-popup>
    <van-action-sheet
      v-model="selectChartShow"
      :actions="charts"
      @select="onSelectChart"
    />
    <van-action-sheet v-model="showRecords" title="流程操作记录">
      <van-steps
        direction="vertical"
        :active="0"
        v-if="
          currentItem.flowStatusRecords &&
          currentItem.flowStatusRecords.length > 0
        "
      >
        <van-step
          v-for="(record, index) in currentItem.flowStatusRecords"
          :key="index"
        >
          <h4>
            {{
              record.trueName +
              (record.flowStatus == 10
                ? "【提交人】，完善了定制信息"
                : record.flowStatus == 14
                ? "【部门负责人】，审核不通过"
                : record.flowStatus == 15
                ? "【部门负责人】，审核通过"
                : record.flowStatus == 20
                ? "【设计】，上传了效果图"
                : record.flowStatus == 29
                ? "【提交人】，需要重新设计效果图"
                : record.flowStatus == 30
                ? "【提交人】，确认了效果图"
                : record.flowStatus == 35
                ? "【设计】，上传了设计文件"
                : record.flowStatus == 40
                ? "【客服】，确认了订单信息"
                : record.flowStatus == 50
                ? "【仓库】，已发货"
                : "")
            }}
          </h4>
          <p>{{ dayjs(record.modifyTime).format("YYYY/MM/DD HH:mm") }}</p>
        </van-step>
      </van-steps>
      <van-empty description="暂无操作记录.." v-else />
    </van-action-sheet>
    <van-action-sheet
      v-model="selectProductShow"
      :actions="productNames"
      @select="onSelectProduct"
    />
  </div>
</template>

<script>
import { Toast, Dialog } from "vant";
import { getPageList, updateFlow,addTrial } from "@/api/customizedWineNeed";
import { checkUserHasDashboardPermission } from "@/api/keshihua";
import { ImagePreview } from "vant";
export default {
  data() {
    return {
      scroll: 0,
      loading: false,
      refreshing: false,
      finished: false,
      list: [],
      page: {
        current: 1,
        pageSize: 10,
        sorter: 0,
      },
      query: {
        timeRange: "",
        orderId: "",
        keyword: "",
        a3CustomerId: "",
        departmentName: "",
        userName: "",
      },
      searchValue: "",
      hasDashboardPermission: false,
      hasDepartmentDashboardPermission: false,
      showDesignImgDialog: false,
      currentItem: {},
      showReDesignImgDialog: false,
      reDesignImgReason: "",
      auditShow: false,

      flowStatus: null,

      sorterOptions: [
        { text: "时间最新【按创建时间最新降序排列】", value: 0 },
        { text: "状态最新【按最新状态变化降序排列】", value: 2 },
        { text: "时间最早", value: 1 },
        { text: "状态最早", value: 3 },
      ],
      flowStatusOptions: [
        { text: "全部状态" },
        { text: "未提交【等待订单归属人完善定制信息】", value: 0 },
        { text: "已提交【等待部门负责人审核】", value: 10 },
        { text: "审核不通过【等待提交人修改定制信息】", value: 14 },
        { text: "审核通过【等待设计上传效果图】", value: 15 },
        { text: "反馈修改效果图【等待设计上传新版效果图】", value: 29 },
        { text: "已上传效果图【等待提交人确认效果图】", value: 20 },
        { text: "已确认效果图【等待设计上传设计文件】", value: 30 },
        { text: "已上传设计文件【等待工厂制作中】", value: 35 },
        { text: "客服已确认【等待仓库发货】", value: 40 },
        { text: "仓库已发货", value: 50 },
      ],
      showCalendar: false,
      minDate: new Date(2022, 3, 7),

      selectChartShow: false,
      showRecords: false,

      selectProductShow: false,
      productNames:[{name:'百年糊涂蓝金定制'},{name:'百年糊涂皇龙樽'},{name:'国色天香（红）定制版'}],
    };
  },
  components: {},
  computed: {
    isDepartmentManager() {
      return (
        this.$store.state.user.mangedHRDepartmentIds &&
        this.$store.state.user.mangedHRDepartmentIds.length > 0
      );
    },
    charts() {
      let arr = [];
      if (this.hasDepartmentDashboardPermission)
        arr.push({
          name: "定制需求统计报表（部门版）",
          url: "/grafana/d/fIv698dVz/ding-zhi-jiu-xu-qiu-tong-ji?orgId=1&theme=light",
        });
      if (this.hasDashboardPermission)
        arr.push({
          name: "定制需求统计报表",
          url: "/grafana/d/mCyrMB3nk/ding-zhi-jiu-xu-qiu-tong-ji-bu-men-ban?orgId=1&theme=light",
        });
      return arr;
    },
  },
  created() {
    checkUserHasDashboardPermission({ dashboardId: 124 }).then((d) => {
      this.hasDashboardPermission = d && d.data.success && d.data.data;
    });
    checkUserHasDashboardPermission({ dashboardId: 100 }).then((d) => {
      this.hasDepartmentDashboardPermission =
        d && d.data.success && d.data.data;
    });
  },
  //此钩子函数会反复触发,是keep-alive特有的钩子函数，取滚动高度
  activated() {
    this.$refs.wrapper.scrollTop = this.scroll || 0;
  },
  //路由离开时的钩子函数,存储滚动高度
  beforeRouteLeave(to, from, next) {
    this.scroll = this.$refs.wrapper.scrollTop || 0;
    next();
  },

  updated() {},
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (from.path == "/customizedwineneed/add") {
        this.onRefresh();
      }
    },
  },
  mounted() {},
  methods: {
    async onLoad() {
      let d = await getPageList({ ...this.page, ...this.query });
      if (!d.data.success && d.data.errorMessage) {
        Toast(d.data.errorMessage);
        return;
      }

      this.list.push(...d.data.data);
      this.loading = false;
      this.refreshing = false;
      if (this.page.current * this.page.pageSize < d.data.total) {
        this.page.current++;
      } else {
        this.finished = true;
      }
    },
    onRefresh() {
      this.list = [];
      this.page.current = 1;
      this.finished = false;
      this.loading = true;
      this.onLoad();
    },
    onSearch() {
      this.list = [];
      this.page.current = 1;
      this.finished = false;
      this.loading = true;
      this.onLoad();
    },
    toadd() {
      // this.$router.push({
      //   path: "/customizedwineneed/add",
      // });
      this.selectProductShow = true;
    },
   async onSelectProduct(item) {
        const res=await addTrial({productName:item.name});
        if(res.data.success){
          this.$router.push({
            path: "/customizedwineneed/add",
            query: { id: res.data.data },
          });
        }
      this.selectProductShow = false;
    },
    toedit(item) {
      this.$router.push({
        path: "/customizedwineneed/add",
        query: { id: item.id },
      });
    },
    tochart() {
      let chartsLen = this.charts.length;
      if (chartsLen > 1) {
        this.selectChartShow = true;
      } else if (chartsLen == 1) this.onSelectChart(this.charts[0]);
    },
    onSelectChart(item) {
      //hideMenu=true隐藏grafana菜单栏，hideHeader=true隐藏grafana头部，hideSubMenuControlsLink=true隐藏查看其他报表按钮
      let url =
        item.url +
        "&hideMenu=true&hideHeader=true&hideSubMenuControlsLink=true";
      // kiosk不传是默认、kiosk=tv、kiosk
      //url += "&kiosk";
      this.$router.push({
        path: "/frame?",
        query: {
          title: item.name,
          url: url,
        },
      });
    },
    showDesignImg(item) {
      this.currentItem = item;
      try {
        this.currentItem.designImgs = JSON.parse(
          this.currentItem.designImgJson
        );
        this.currentItem.designImgs.sort((a, b) => b.Version - a.Version);
        if (this.currentItem.flowStatus == 29) {
          this.currentItem.designImgs.splice(0, 1);
        }
      } catch (e) {
        this.currentItem.designImgs = [];
      }
      this.showDesignImgDialog = true;
    },
    previewImg(imgs) {
      ImagePreview({
        images: imgs,
        closeable: true,
      });
    },
    async confirmDesign() {
      Dialog.confirm({
        title: "确认设计图后流程无法撤回，是否继续？",
      }).then(async () => {
        const d = await updateFlow({
          id: this.currentItem.id,
          flowStatus: 30,
        });
        if (d.data.success) {
          Toast("已确认设计图，请耐心等待工厂制作完成后仓库发货。");
          this.showDesignImgDialog = false;
          this.currentItem.flowStatus = 30;
        } else {
          Toast(d.data.errorMessage);
        }
      });
    },
    async reDesign() {
      this.showReDesignImgDialog = true;
    },
    async submitReDesign() {
      const d = await updateFlow({
        id: this.currentItem.id,
        flowStatus: 29,
        reason: this.reDesignImgReason,
      });
      if (d.data.success) {
        Toast("请耐心等待修改设计图。");
        this.currentItem.flowStatus = 29;
        this.showDesignImgDialog = false;
        this.showReDesignImgDialog = false;
      } else {
        Toast(d.data.errorMessage);
      }
    },
    async audit(pass) {
      let flowStatus = pass ? 15 : 14;
      const d = await updateFlow({
        id: this.currentItem.id,
        flowStatus: flowStatus,
      });
      if (d.data.success) {
        this.currentItem.flowStatus = flowStatus;
        this.auditShow = false;
        Toast("审核成功");
      } else {
        Toast(d.data.errorMessage);
      }
    },
    toaudit(item) {
      this.currentItem = item;
      this.auditShow = true;
    },
    tohelp() {
      this.$router.push({
        path: "/bbs/detail?",
        query: {
          para: "2607c180-64ef-4768-9272-334ccbb3f14b",
          type: 0,
          back: true,
        },
      });
    },

    async onReset() {
      this.query.timeRange = "";
      this.query.keyword = "";
      this.query.a3CustomerId = "";
      this.query.departmentName = "";
      this.query.userName = "";
    },
    async onSelectedTimeRange(date) {
      const [start, end] = date;
      this.showCalendar = false;
      this.query.timeRange = `${this.dayjs(start).format(
        "YYYY/MM/DD"
      )} - ${this.dayjs(end).format("YYYY/MM/DD")}`;
      this.$refs.filter.toggle(true);
      await this.onSearch();
    },
    toShowRecords(item) {
      this.currentItem = item;
      if (this.currentItem.flowStatusRecords)
        this.currentItem.flowStatusRecords.sort((a,b)=>new Date(b.modifyTime) - new Date(a.modifyTime));
      this.showRecords = true;
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}

.van-cell-title4 {
  -webkit-box-flex: 4;
  flex: 4;
}
</style>